const OptionTypes = {
    NEED_RADIO: "Tarvitsen radiokouluttajan",
    NEED_PLHM: "Tarvitsen PL-mesun" ,
    NEED_NHM: "Tarvitsen NOVA-mesun",
    NEED_COACH: "Tarvitsen VPK:n",
    WORK_PLHM:"PL-mesu",
    WORK_NHM:"NOVA-mesu",
    WORK_RADIO:"Radiokouluttaja",
    WORK_TDM:"Tandemhyppymestari",
    WORK_COACH:"VPK"
};

export const Options = Object.freeze(OptionTypes);