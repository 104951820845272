import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function RemoveDialog({
  rowToRemove,
  showRemoveDialog,
  setShowRemove,
  removeSkydiver,
}) {
  const handleRemove = () => {
    removeSkydiver(rowToRemove.id);
    setShowRemove(false);
  };

  return (
    <Modal show={showRemoveDialog}>
      <Modal.Header closeButton>
        <Modal.Title>Vahvista poisto</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Vahvista hyppääjän {rowToRemove.who} poisto.</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowRemove(false)}>
          Peru
        </Button>
        <Button variant="danger" onClick={handleRemove}>
          Vahvista poisto
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
