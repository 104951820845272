import Form from "react-bootstrap/Form";
export default function StudentSelector({
  instructorRequirements,
  onInstructorRequirementsChange,
  instructorLevel,
  onInstructorLevelChange,
}) {
  return (
    <div>
      <Form.Label>Valitse kouluttajatiedot:</Form.Label>
      <Form.Check
        type="checkbox"
        label={"Tarvitsen radiokouluttajan"}
        name="specialrolegroup"
        id="radiokouluttaja"
        checked={instructorRequirements.includes("Tarvitsen radiokouluttajan")}
        onChange={(e) =>
          onInstructorRequirementsChange(
            "Tarvitsen radiokouluttajan",
            e.target.checked
          )
        }
      />
      {["PL-mesu", "VPK:", "NOVA-mesu"].map((mesu) => (
        <Form.Check
          type="radio"
          name="specialrolegroup"
          label={`Tarvitsen ${mesu}n`}
          id={mesu}
          required
          checked={instructorLevel === `Tarvitsen ${mesu}n`}
          onChange={(e) => onInstructorLevelChange(`Tarvitsen ${mesu}n`)}
        />
      ))}{" "}
    </div>
  );
}
