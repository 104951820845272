import Form from "react-bootstrap/Form";

export default function LicensedSelector({
  instructorQualifications,
  onInstructorQualificationChange,
}) {
  return (
    <div>
      <Form.Label>Olen käytettävissä:</Form.Label>
      {[
        "PL-mesu",
        "NOVA-mesu",
        "VPK",
        "Radiokouluttaja",
        "Tandemhyppymestari",
      ].map((tyyppi) => (
        <Form.Check
          type="checkbox"
          name="specialrolegroup"
          label={tyyppi}
          key={tyyppi}
          checked={instructorQualifications.includes(tyyppi)}
          onChange={(e) =>
            onInstructorQualificationChange(tyyppi, e.target.checked)
          }
        />
      ))}
    </div>
  );
}
