import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

/**
 * Dialog that shows a short help about how the program should be used
 *
 * @param {*} param0
 * @returns
 */
export default function HelpDialog({ showHelpDialog, setShowHelpDialog }) {
  return (
    <>
      <Modal show={showHelpDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Ohjeet</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Valitse päivä käyttämällä valintanappuloita.</p>

          <p>
            Lisää itsesi painamalla "Lisää minut"-nappulaa. Muokkausta ei
            toistaiseksi ole saatavilla, vaan sinun pitää poistaa
            ilmoittautuminen ja tehdä uusi. Tämä on kehityslistalla ensimmäisenä
            parannuksena.
          </p>

          <p>
            Määritä parhaasi mukaan, mihin aikaan olet valmis hyppäämään, ei
            sitä mihin aikaan pääset töistä tai milloin kävelet kerhon ovesta
            sisään.
          </p>

          <p>
            <i>
              Esimerkki: Työt loppuu 16:00, ajat kerholle 30 minuuttia. Juot
              kahvit ja laitat kamat valmiiksi. Älä merkkaa Hyppyvalmiutta klo
              16:30 vaan mieluummin 17:00.
            </i>
          </p>

          <p>
            Pyri tarkentamaan aikataulujasi jos ne muuttuvat oleellisesti ja
            poista itsesi riittävän ajoissa jos joudut perumaan. Jos poistat
            itsesi aivan viime minuuteilla, varmista että mahdollisest oppilaasi
            tai muut hyppääjät saavat tästä tiedon jotakin reittiä pitkin.
          </p>

          <p>
            Kannattaa joka tapauksessa tarkistaa kalenteri ennen kerholle tuloa,
            mutta muista että kaikki eivät välttämättä merkanneet tuloaan.
          </p>

          <p>
            <i>
              Pokaan pääsee saapumisjärjestyksessä kun nimi on kerhon taululla,
              eikä tämän listan perusteella!
            </i>
          </p>

          <h2>Kelpparit:</h2>

          <p>
            Määritä tehtävät, joissa voit toimia päivän aikana. Tehtävän
            valintaa/valisematta jättämistä ei tarvitse kohdella ehdottomana.
          </p>
          <h2>Oppilaat:</h2>

          <p>
            Merkkaa itsesi oppilaaksi ja valitse aina, minkätasoisen kouluttajan
            tarvitset ja tarvitsetko radiokouluttajaa. Huomauta myös, puuttuuko
            jotain kokeita tai näytteitä, mutta muista ettei näille ole
            välttämättä aikaa kesken hyppytoiminnan. Voit myös laittaa
            oppilaiden whatsapp &#8209;ryhmään viestin jos jotakin oleellista
            puuttuu ennen hyppäämään lähtöä.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowHelpDialog(false)}>
            Sulje
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
