import Form from "react-bootstrap/Form";
import TimePicker from "react-bootstrap-time-picker";
import StudentSelector from "./StudentSelector";
import LicensedSelector from "./LicensedSelector";
import { useState, useEffect } from "react";

import { Options } from "../util/options";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function SkydiverDialog({
  showAddDialog,
  setShowAddDialog,
  registerSkydiver,
  updateSkydiver,
  toEdit,
}) {
  useEffect(() => {
    if (toEdit !== undefined) {
      setOldID(toEdit.id);
      setName(toEdit.who);
      setInfo(toEdit.note);
      setTime(toEdit.when);
      setSelectedRole(toEdit.proficiency);
      const instructorData = [];
      const studentData = [];
      for (const option of toEdit.options) {
        switch (option.value) {
          case Options.WORK_PLHM:
          case Options.WORK_NHM:
          case Options.WORK_RADIO:
          case Options.WORK_TDM:
          case Options.WORK_COACH:
            instructorData.push(option.value);
            break;
          case Options.NEED_PLHM:
          case Options.NEED_NHM:
          case Options.NEED_COACH:
            setInstructorLevel(option.value);
            break;
          case Options.NEED_RADIO:
            studentData.push(option.value);
            break;
          default:
            console.log("unrecognized property:" + option.value);
        }
      }
      setInstructorQualifications(instructorData);
      setInstructorRequirements(studentData);
      //   setShowAddDialog(true);
    }
  }, [toEdit]);

  const [oldID, setOldID] = useState(-1);
  const [newname, setName] = useState("");
  const [newinfo, setInfo] = useState("");
  const [newtime, setTime] = useState("9:00");
  const [selectedRole, setSelectedRole] = useState("");

  const [instructorQualifications, setInstructorQualifications] = useState([]);
  const [instructorRequirements, setInstructorRequirements] = useState([]);
  const [instructorLevel, setInstructorLevel] = useState("");

  const onInstructorQualificationChange = (id, selected) => {
    setInstructorQualifications((prev) =>
      selected ? [...prev, id] : [...prev].filter((listId) => id !== listId)
    );
  };

  const onInstructorLevelChange = (selected) => {
    setInstructorLevel(selected);
  };

  const onInstructorRequirementsChange = (id, selected) => {
    setInstructorRequirements((prev) =>
      selected ? [...prev, id] : [...prev].filter((listId) => id !== listId)
    );
  };

  /*
    const onInstructorLevelChange = (id, selected) => {
      setInstructorQualifications(prev => selected ? [...prev, id] : [...prev].filter(listId => id !== listId))
    }
   */

  const onProficiencyChange = (type) => {
    setSelectedRole(type.target.id);
  };

  const onTimeChange = (time) => {
    const hour = Math.floor(time / 3600);
    const minute = Math.floor((time % 3600) / 60);
    const actualtime = hour + ":" + minute + (minute === 0 ? "0" : "");
    setTime(actualtime);
  };

  function toObjects(list) {
    var x = list.map((e) => ({ value: e }));
    return x;
  }

  const handleSubmit = (e) => {
    const data = {
      who: newname,
      when: newtime,
      note: newinfo,
      id: oldID,
      proficiency: selectedRole,
      options:
        selectedRole === "oppilas"
          ? toObjects([...instructorRequirements, instructorLevel])
          : toObjects(instructorQualifications),
    };
    console.log(toEdit);
    if (toEdit === undefined) {
      registerSkydiver(e, data);
    } else {
      updateSkydiver(e, data);
    }
  };

  return (
    <Modal
      show={showAddDialog}
      onHide={() => setShowAddDialog(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Lisää hyppääjä</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="lisäys" onSubmit={handleSubmit}>
          <Form.Label> Nimi: </Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Nimi"
            onChange={(e) => setName(e.target.value)}
            value={newname}
          />
          <Form.Label htmlFor="valitseAika">Kamat kireellä klo:</Form.Label>
          <TimePicker
            id="valitseAika"
            start="9:00"
            end="21:00"
            step={15}
            format={24}
            onChange={onTimeChange}
            value={newtime}
          />
          <Form.Label> Muita tietoja: </Form.Label>
          <Form.Control
            type="text"
            placeholder="Lisätiedot"
            onChange={(e) => setInfo(e.target.value)}
            value={newinfo}
          />

          <Form.Check
            type="radio"
            name="kelpoisuus"
            label="Kelppari"
            id="kelppari"
            required
            checked={selectedRole === "kelppari"}
            onChange={onProficiencyChange}
          />

          <Form.Check
            type="radio"
            name="kelpoisuus"
            label="Oppilas"
            id="oppilas"
            required
            checked={selectedRole === "oppilas"}
            onChange={onProficiencyChange}
          />

          <PropsSelector
            type={selectedRole}
            instructorRequirements={instructorRequirements}
            onInstructorRequirementsChange={onInstructorRequirementsChange}
            instructorQualifications={instructorQualifications}
            onInstructorQualificationChange={onInstructorQualificationChange}
            onInstructorLevelChange={onInstructorLevelChange}
            instructorLevel={instructorLevel}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          type="reset"
          onClick={() => setShowAddDialog(false)}
        >
          En sittenkään
        </Button>
        <Button variant="primary" type="submit" form="lisäys">
          Jee!
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function PropsSelector(props) {
  const role = props.type;
  if (role === "" || role === null || role === undefined) {
    return <></>;
  } else if (role === "oppilas") {
    return (
      <StudentSelector
        instructorRequirements={props.instructorRequirements}
        onInstructorRequirementsChange={props.onInstructorRequirementsChange}
        instructorLevel={props.instructorLevel}
        onInstructorLevelChange={props.onInstructorLevelChange}
      />
    );
  } else if (role === "kelppari") {
    return (
      <LicensedSelector
        instructorQualifications={props.instructorQualifications}
        onInstructorQualificationChange={props.onInstructorQualificationChange}
      />
    );
  }
}
