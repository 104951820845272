import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

export default function SkydiverRow({
  nimi,
  kamat,
  notes,
  options,
  handleEditSkydiver,
  removeSkydiver,
}) {
  const roles = (
    <Stack direction="horizontal">
      {options?.map((o) => (
        <span key={o.role} className="role">
          {o.value}
        </span>
      ))}
    </Stack>
  );

  const namecell =
    nimi.toLowerCase() === "muikee" ? (
      <td className="muikee">{nimi}</td>
    ) : (
      <td>{nimi}</td>
    );

  return (
    <tr>
      {namecell}
      <td>{kamat}</td>
      <td>
        {roles}
        {notes}
      </td>
      <td>
        {kamat !== "-" ? (
          <Stack direction="horizontal" gap={3}>
            <Button onClick={(e) => handleEditSkydiver()} variant="warning">
              <i className="fa-solid fa-pen-to-square"></i>
            </Button>
            <Button onClick={(e) => removeSkydiver()} variant="danger">
              <i className="fa-solid fa-trash"></i>
            </Button>
          </Stack>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}

export function PilotRow({ name, shift, note }) {
  return (
    <tr>
      <td>{name}</td>
      <td>{shift}</td>
      <td>
        <i>{note}</i>
      </td>
      <td> </td>
    </tr>
  );
}

export function TandemRow({ info, time, note }) {
  return (
    <tr>
      <td>{info}</td>
      <td>{time}</td>
      <td>
        <i>{note}</i>
      </td>
      <td> </td>
    </tr>
  );
}
