import Table from "react-bootstrap/Table";
import SkydiverRow from "./TableRows";
import { PilotRow, TandemRow } from "./TableRows"; //why import like this

import { useState } from "react";

import RemoveDialog from "./RemoveDialog";

export default function RosterTable({
  currentRoster,
  selectedDateStr,
  tandemInfo,
  pilotInfo,
  removeSkydiver,
  setEditSkydiver,
  setShowEditDialog,
}) {
  const [showRemoveDialog, setShowRemove] = useState(false);

  const [rowToRemove, setRowToRemove] = useState("");

  const blankLine = (
    <SkydiverRow
      key="skydiver-row-empty"
      nimi="-"
      kamat="-"
      notes="-"
      id="-"
      options={[]}
      editSkydiver={() => {}}
      removeSkydiver={() => {}}
    />
  );

  function preprocessBecauseOfUsers(tandemdata){
    tandemdata = tandemdata.replace(/s+/,","); // replace whitespace with commas.
    tandemdata = tandemdata.replace(/,+/,","); // them multiple commans with a single comma.
    // then, because users type 12:00, 16:30 possibly like this: 12,16,30
    // we'll do a stupid replacement here:
    tandemdata = tandemdata.replace(/,15/,":15");
    tandemdata = tandemdata.replace(/,30/,":30");
    tandemdata = tandemdata.replace(/,45/,":45");
    return tandemdata;
  }

  function tandemsToObjects(tdmdata) {
    if (tdmdata === undefined || tdmdata.length === 0) {
      return [
        {
          info: "Ei tietoa tandemeista.",
          time: "",
          note: "Haettu automaattisesti lentäjäkalenterista.",
        },
      ];
    }

    //TODO: should get a better value as input, not the whote thing
    const raw = preprocessBecauseOfUsers(tdmdata[0].data).split(",");
    const list = [];
    for (let value of raw) {
      if (value.match(/^[0-9][0-9]$/)) {
        // 19 or 20 or 11
        list.push(value + ":00");
      } else if (value.match(/[0-9][0-9][:.][0-9][0-9]/)) {
        // 19.00 or 20:00 or 11:00
        list.push(value.replace(".", ":"));
      } else if (value.match(/^[0-9][0-9][0-9][0-9]/)) {
        // 19.00 or 20:00 or 11:00
        list.push(value.slice(0, 2) + ":" + value.slice(2));
      } else if (value.match(/2x[0-9][0-9]$/)) {
        //2x14
        list.push(value + ":00");
      } else if (value.match(/2x[0-9][0-9]:[0-9][0-9]$/)) {
        //2x14:00
        list.push(value);
      } else if (value.match(/2x[0-9][0-9][0-9][0-9]$/)) {
        //2x1400
        list.push(value.slice(0, 4) + ":" + value.slice(4));
      } else if (value.match(/[0-9][0-9]x2$/)) {
        //2x1400
        list.push("2x" + value.slice(0, 2) + ":00");
      } else {
        list.push(value + " (?)");
      }
    }
    var x = list.map((e) => ({
      info: "Tandem",
      time: e,
      note: "Haettu automaattisesti lentäjäkalenterista.",
    }));
    return x;
  }

  function formatTime(time){
    if(time.match(/^[0-9][:][0-9][0-9]/)){
      return "0"+time;
    }
    return time;
  }

  function filterList(proficiency) {
    return currentRoster[selectedDateStr] === undefined
      ? []
      : currentRoster[selectedDateStr].skydivers
        ?.sort((a, b) => (formatTime(a.when) < formatTime(b.when) ? -1 : 1))
          .filter((row) => row.proficiency === proficiency)
          .map((row) => (
            <SkydiverRow
              key={"skydiver-row-" + row.id}
              nimi={row.who}
              kamat={row.when}
              notes={row.note}
              id={row.id}
              options={row.options}
              handleEditSkydiver={() => {
                setEditSkydiver(row);
                setShowEditDialog(true);
              }}
              removeSkydiver={() => {
                setRowToRemove(row);
                setShowRemove(true);
              }}
            />
          ));
  }

  const tdmList = tandemsToObjects(tandemInfo[selectedDateStr]);

  const kelppariList = filterList("kelppari");

  if (!kelppariList.length) {
    kelppariList.push(blankLine);
  }

  const oppilasList = filterList("oppilas");

  if (!oppilasList.length) {
    oppilasList.push(blankLine);
  }

  return (
    <div>
      <Table bordered hover responsive>
        <thead className="table-header">
          <tr>
            <td>Kelpparit</td>
            <td>Kamat päällä</td>
            <td>Lisätiedot</td>
            <td></td>
          </tr>
        </thead>
        <tbody>{kelppariList}</tbody>

        <thead className="table-header">
          <tr>
            <td>Oppilaat</td>
            <td>Kamat päällä</td>
            <td>Lisätiedot</td>
            <td></td>
          </tr>
        </thead>
        <tbody>{oppilasList}</tbody>

        <thead className="table-header">
          <tr>
            <td>Pilotti</td>
            <td>Lentovuoro</td>
            <td>Lisätiedot</td>
            <td></td>
          </tr>
        </thead>

        <tbody>
          {pilotInfo[selectedDateStr]?.length > 0 ? (
            pilotInfo[selectedDateStr]
              ?.sort((a, b) => (a.morning < b.morning ? 1 : -1))
              .map((row) => (
                <PilotRow
                  key={row.morning}
                  name={row.who}
                  shift={row.morning ? "Aamupäivä" : "Iltapäivä"}
                  note={"Haettu automaattisesti lentäjäkalenterista."}
                />
              ))
          ) : (
            <PilotRow
              key={"tyhjä"}
              name={"Ei lentäjää."}
              shift={""}
              note={"Haettu automaattisesti lentäjäkalenterista."}
            />
          )}
        </tbody>

        <thead className="table-header">
          <tr>
            <td>Tandemit</td>
            <td>Klo</td>
            <td>Lisätiedot</td>
            <td></td>
          </tr>
        </thead>

        <tbody>
          {tdmList.map((row) => (
            <TandemRow info={row.info} time={row.time} note={row.note} />
          ))}
        </tbody>
      </Table>
      <span>
        <i>Lentäjäkalenterin tiedot päivittyvät noin vartin välein.</i>
      </span>

      <RemoveDialog
        rowToRemove={rowToRemove}
        showRemoveDialog={showRemoveDialog}
        removeSkydiver={removeSkydiver}
        setShowRemove={setShowRemove}
      />
    </div>
  );
}
