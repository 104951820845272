import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

import { formattedDateFromOffset } from "../util/dateutils";

export default function Datebuttons(props) {
  let a = [...Array(8).keys()];
  const buttons = a.map((i) => {
    let variant = "";
    const hasPilots = props.pilotInfo[formattedDateFromOffset(i)]?.length > 0;
    const hasSkydivers =
      props.currentRoster[formattedDateFromOffset(i)]?.skydivers.length > 0;

    if (hasPilots && hasSkydivers) {
      variant = "success";
    } else if (hasSkydivers) {
      variant = "warning";
    } else if (hasPilots) {
      variant = "primary";
    } else {
      variant = "secondary";
    }
    variant =
      formattedDateFromOffset(i) === props.selectedDate
        ? variant
        : "outline-" + variant;

    return (
      <Button
        className="datebutton"
        key={i}
        variant={variant}
        onClick={(e) => {
          props.getRegisteredSkydivers(formattedDateFromOffset(i));
          props.setSelectedDateStr(formattedDateFromOffset(i));
        }}
      >
        {displayDateFromOffset(i)}
      </Button>
    );
  });

  const nappulat = (
    <div className="button-rows">
      <Stack className="buttonstack" direction="horizontal" gap={3}>
        {" "}
        {buttons.slice(0, 4)}
      </Stack>
      <Stack className="buttonstack" direction="horizontal" gap={3}>
        {" "}
        {buttons.slice(4)}
      </Stack>
    </div>
  );

  return nappulat;
}

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function displayDateFromOffset(i) {
  let date = new Date();
  var newdate = new Date(date.setDate(date.getDate() + i));
  let day = newdate.getDate();
  let month = newdate.getMonth() + 1;
  let year = newdate.getFullYear();
  let name = newdate.toLocaleString("fi-FI", { weekday: "short" });
  let currentDate = `${toTitleCase(name)}\n${day}.${month}.`;
  return currentDate;
}
