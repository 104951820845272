
export function formattedDateFromOffset(i) {
    const date = new Date();
    const newdate = new Date(date.setDate(date.getDate() + i));
    const day = ("0"+ newdate.getDate()).slice(-2);
    const month = ("0"+(newdate.getMonth() + 1)).slice(-2);
    const year = newdate.getFullYear();
    const currentDate = `${day}.${month}.${year}`;
    return currentDate;
  }


