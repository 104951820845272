import "bootstrap/dist/css/bootstrap.min.css";

import axios from "axios";
import { useState, useEffect } from "react";

import Button from "react-bootstrap/Button";

import { formattedDateFromOffset } from "./util/dateutils";

import RosterTable from "./components/RosterTable";
import SkydiverDialog from "./components/SkydiverDialog";
import HelpDialog from "./components/HelpDialog";
import DateSelector from "./components/DateSelector";

function App() {
  // this weeks skydivers
  const [currentRoster, setCurrentRoster] = useState({});
  const [pilotInfo, setPilotInfo] = useState({});
  const [tandemInfo, setTandemInfo] = useState({});

  const [currentDateStr, setCurrentDateStr] = useState("");
  const [selectedDateStr, setSelectedDateStr] = useState("");

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const [editedSkydiver, setEditSkydiver] = useState(undefined);

  const handleShowAddDialog = () => setShowAddDialog(true);

  /*
   *  Used to fetch initial data from the server.
   */
  useEffect(() => {
    setCurrentDateStr(formattedDateFromOffset(0));
    setSelectedDateStr(formattedDateFromOffset(0));

    axios.get(`/api/v1/roster/`).then((response) => {
      setCurrentRoster(response.data.jumpdays);
    });

    axios.get(`/api/v1/pilots/`).then((response) => {
      setPilotInfo(response.data.pilots);
    });

    axios.get(`/api/v1/tandems/`).then((response) => {
      setTandemInfo(response.data.tandems);
    });
    /*
    axios.get(`/api/v1/updatetime/`)
    .then(response => {setSynchronisedTime(response.data.updatetime)
    });*/
  }, []);

  return (
    <div className="main-body">
      <div className="buttonbox">
        <DateSelector
          pilotInfo={pilotInfo}
          currentRoster={currentRoster}
          getRegisteredSkydivers={getRegisteredSkydivers}
          selectedDate={selectedDateStr}
          setSelectedDateStr={setSelectedDateStr}
        />
      </div>
      <div className="table-container">
        <RosterTable
          currentRoster={currentRoster}
          selectedDateStr={selectedDateStr}
          tandemInfo={tandemInfo}
          pilotInfo={pilotInfo}
          removeSkydiver={removeSkydiver}
          registerSkydiver={registerSkydiver}
          setEditSkydiver={setEditSkydiver}
          setShowEditDialog={setShowAddDialog} //TODO: naming consistency
        />
      </div>

      <div className="footer">
        <Button onClick={handleShowAddDialog} variant="success">
          Lisää minut
        </Button>{" "}
        <Button onClick={() => setShowHelpDialog(true)} variant="secondary">
          Apua
        </Button>{" "}
      </div>
      <HelpDialog
        showHelpDialog={showHelpDialog}
        setShowHelpDialog={setShowHelpDialog}
      />

      <SkydiverDialog
        showAddDialog={showAddDialog}
        setShowAddDialog={setShowAddDialog}
        registerSkydiver={registerSkydiver}
        updateSkydiver={updateSkydiver}
        toEdit={editedSkydiver}
      />
    </div>
  );

  function getRegisteredSkydivers(date) {
    axios.get(`/api/v1/roster/`).then((response) => {
      setCurrentRoster(response.data.jumpdays);
    });
  }

  function removeSkydiver(id) {
    axios.delete(`/api/v1/roster/${id}`).then((res) => {
      const roster = { ...currentRoster };
      roster[currentDateStr].skydivers = [
        ...roster[currentDateStr].skydivers,
      ].filter((a) => a.id !== id);
      setCurrentRoster(roster);
    });
  }

  function registerSkydiver(e, skydiver) {
    e.preventDefault();

    setShowAddDialog(false); // ??? miksei ole automaattinen

    axios
      .post(`/api/v1/roster/${selectedDateStr}`, skydiver)
      .then((response) => {
        setCurrentRoster(response.data.jumpdays);
      });
  }

  function updateSkydiver(e, skydiver) {
    e.preventDefault();

    setShowAddDialog(false); // ??? miksei ole automaattinen

    axios.patch(`/api/v1/roster/${skydiver.id}`, skydiver).then((response) => {
      setCurrentRoster(response.data.jumpdays);
      setEditSkydiver(undefined);
    });
  }
}
/*
window.addEventListener('scroll', function() {
  var footer = document.querySelector('.footer');
  var content = document.querySelector('.main-body');
 // console.log(content);
  
  var contentBottom = content.offsetTop + content.offsetHeight;
  var footerTop = footer.offsetTop;
 // console.log(contentBottom+" "+ footerTop);
 console.log(window.scrollY+" " + window.innerHeight+" " +(window.scrollY + window.innerHeight)+" " +contentBottom)
  if (contentBottom <= window.innerHeight) {
    // Content fits within viewport, so the footer should not float
    footer.style.position = 'static';
    console.log("static (if)")
  } else if (window.scrollY + window.innerHeight <= contentBottom-50 && footerTop < contentBottom) {
    // Footer is scrolled off the screen
    console.log("should float")
    footer.style.position = 'fixed';
    footer.style.bottom = '0';
  } else {
    console.log("static (else)")
    // Footer is still within the viewport
    footer.style.position = 'static';
  }
});*/

export default App;
